import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import CompanyIcon from "../assets/logo.png";
import { styled } from "@mui/material/styles";

const ResponsiveLogo = styled("img")(({ theme }) => ({
  width: "200px",
  height: "auto",
  cursor: "pointer",
  marginLeft: '250px',
  [theme.breakpoints.down('md')]:{
    marginLeft:'0px'
    
  }
}));

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 40px",
        marginTop: "20px",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        position: "sticky",
        top: 0,
        zIndex: 1000,
      }}
    >
      {/* Logo */}
      <ResponsiveLogo src={CompanyIcon} alt="Company Logo" />

      {/* Desktop Navigation */}
      <Box
        sx={{
          display: { xs: "none", md: "flex" }, // Hide on mobile, show on larger screens
          justifyContent: "space-between",
          alignItems: "center",
          gap: "30px",
        }}
      >
        {["Home", "About Us", "Services", "Blogs"].map((item, index) => (
          <Typography
            key={index}
            variant="h6"
            component="div"
            sx={{
              position: "relative",
              cursor: "pointer",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: "bold",
              color: "text.primary",
              transition: "color 0.3s ease-in-out",
              "&:hover": {
                color: "red",
              },
              "&:after": {
                content: '""',
                position: "absolute",
                bottom: "-5px",
                left: 0,
                width: 0,
                height: "2px",
                backgroundColor: "red",
                transition: "width 0.3s ease-in-out",
              },
              "&:hover:after": {
                width: "100%",
              },
            }}
          >
            {item}
          </Typography>
        ))}
      </Box>

      {/* Desktop Contact Button */}
      <Button
        sx={{
          display: { xs: "none", md: "flex" }, // Hide on mobile, show on larger screens
          borderRadius: "30px",
          backgroundColor: "black",
          color: "white",
          height: "56px",
          width: "151px",
          fontFamily: "'Poppins', sans-serif",
          fontSize: "16px",
          textTransform: "none",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          transition: "background-color 0.3s, color 0.3s",
          "&:hover": {
            backgroundColor: "white",
            color: "#FF1A1A",
            border: "1px solid #FF1A1A",
          },
        }}
      >
        Contact Us
      </Button>

      {/* Mobile Menu Icon */}
      <IconButton
        sx={{
          display: { xs: "flex", md: "none" }, // Show on mobile, hide on larger screens
        }}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>

      {/* Drawer for Mobile Menu */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            padding: "20px",
          }}
          role="presentation"
        >
          {/* Drawer Header with Close Icon */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <IconButton
              sx={{ marginLeft: "80%" }}
              onClick={toggleDrawer(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Drawer Menu Items */}
          <List>
            {["Home", "About Us", "Services", "Blogs"].map((item, index) => (
              <ListItem button key={index}>
                <ListItemText
                  primary={item}
                  primaryTypographyProps={{
                    sx: {
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "black",
                    },
                  }}
                />
              </ListItem>
            ))}
            <ListItem button>
              <Button
                sx={{
                  borderRadius: "30px",
                  backgroundColor: "black",
                  marginRight:'30px',
                  color: "white",
                  width: "100%",
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: "16px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#FF1A1A",
                    border: "1px solid #FF1A1A",
                  },
                }}
              >
                Contact Us
              </Button>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Header;
