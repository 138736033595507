import React, { useRef } from "react";
import "./App.css";
import AboutSection from "./components/aboutSection";
import Header from "./components/header";
import HeroSection from "./components/heroSection";
import ProjectSection from "./components/projectSection";
import ServiceSection from "./components/serviceSection";
import TestimonialCarousel from "./components/testimonialCard";
import NewsAndBlogs from "./components/blogSection";
import Footer from "./components/footer";

function App() {
  // Create refs for each section
  const heroRef = useRef(null);
  const aboutRef = useRef(null);
  const serviceRef = useRef(null);
  const projectRef = useRef(null);
  const testimonialRef = useRef(null);
  const blogRef = useRef(null);

  return (
    <div className="App">
      <Header
        heroRef={heroRef}
        aboutRef={aboutRef}
        serviceRef={serviceRef}
        projectRef={projectRef}
        testimonialRef={testimonialRef}
        blogRef={blogRef}
      />
      <HeroSection ref={heroRef} />
      <AboutSection ref={aboutRef} />
      <ServiceSection ref={serviceRef} />
      <ProjectSection ref={projectRef} />
      <TestimonialCarousel ref={testimonialRef} />
      <NewsAndBlogs ref={blogRef} />
      <Footer />
    </div>
  );
}

export default App;
